.container {
  position: relative;
  max-width: 200px;
  margin: 16px 0;
}

.cardContainer {
  width: 100%;
  height: 156px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: solid transparent 3px;
  border-radius: 8px;
  color: var(--trs-gray-30);
  display: block;
  box-sizing: border-box;
}

.cardContainer:hover {
  color: var(--trs-gray-30);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 3px solid var(--trs-gray-80);
}

.disabledCard {
  opacity: 0.8;
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

.cardThumbnailContainer {
  height: 50%;
  width: 100%;
  background: #fafafa;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 10px;
  border-radius: 8px 8px 0 0;
  position: relative;
}

.cardTextContainer {
  height: 50%;
  width: 100%;
  padding: 16px;
  background: white;
  border-radius: 0 0 8px 8px;
}

.imageLayout {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 70%;
  height: auto;
}

.innerPillContainer {
  width: 90px;
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-top: 4px;
  text-align: center;
  border-radius: 16px;
  opacity: 1;

  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.disabledPill {
  color: #5c1f7a;
  background: linear-gradient(
      0deg,
      rgba(92, 31, 122, 0.1),
      rgba(92, 31, 122, 0.1)
    ),
    #ffffff;
}

.enabledPill {
  color: var(--trs-destructive-red);
  background-color: var(--alert-error-background);
}
