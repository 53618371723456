.container {
  background-color: var(--trs-gray-98);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  max-width: 700px;
  width: 90%;
}

.contentBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
}

.header {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 4px #d7282f;
  padding: 45px 0 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.headerText {
  padding-top: 10px;
  font-size: 24px;
  font-weight: 800;
}

.header img {
  width: 120px;
}

.testHeader {
  background-color: #f4ecf9;
  border-bottom: solid 4px var(--trs-customer-order-purple);
}

.stagingHeader {
  background-color: #fadfd1;
  border-bottom: solid 4px var(--trs-training-orange);
}
