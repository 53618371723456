.table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.tableNoBorders {
  width: 100%;
  border-spacing: 0;
}

.header {
  background-color: #f7f7f7;
}

.header {
  border-radius: 5px;
}

.header .th:first-child {
  border-top-left-radius: 5px;
}

.header .th:last-child {
  border-top-right-radius: 5px;
}

.table .row:last-child .cell:first-child {
  border-bottom-left-radius: 5px;
}

.table .row:last-child .cell:last-child {
  border-bottom-right-radius: 5px;
}

.row {
  padding: 20px;
}

.body .row:hover {
  background-color: #ebf5fb;
}

.selectedRow {
  background-color: #ebf5fb;
}

.selectedRow > td {
  border-bottom: 2px solid var(--trs-primary-action-blue);
  border-top: 2px solid var(--trs-primary-action-blue);
}

.selectedRow > td:first-child {
  border-left: 2px solid var(--trs-primary-action-blue);
}

.selectedRow > td:last-child {
  border-right: 2px solid var(--trs-primary-action-blue);
}

.th {
  padding: 10px;
  text-align: left;
}

.cell {
  border-top: 1px solid #e0e0e0;
  padding: 16px 8px;
  vertical-align: top;
}

.cellNoPadding {
  border-bottom: 1px solid #e0e0e0;
  vertical-align: top;
}

.cellNoBorder {
  padding: 16px 8px;
  vertical-align: top;
}
