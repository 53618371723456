.valuesContainer {
  border-radius: 8px;
  background-color: #fafafa;
  padding: 16px;
}

.attributeGrid {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 16px;
}

.attributeGrid > :first-child {
  text-align: end;
}

.attributeGrid > :last-child {
  text-align: start;
}

.attributeGrid p {
  color: var(--text-color-grey2);
  font-size: 15px;
  margin-bottom: 16px;
}
