.tableContainer {
  display: none;
}

.gridContainer {
  display: block;
}

@media (min-width: 576px) {
  .tableContainer {
    display: unset;
  }

  .gridContainer {
    display: none;
  }
}
