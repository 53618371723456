.inputDisplay {
  margin-top: 4px;
  margin-bottom: 16px;
}

.inputXSmall {
  width: 25%;
  max-width: 75px;
  margin-right: 20px;
}

.inputSmall {
  width: 33%;
  max-width: 120px;
  margin-right: 20px;
}

.inputMedium {
  width: 50%;
  max-width: 190px;
  margin-right: 20px;
}

.inputLarge {
  width: 75%;
  max-width: 305px;
  margin-right: 20px;
}

.inputXLarge {
  width: 100%;
  max-width: 400px;
}

.inputStyle {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  padding: 8px 8px;
  height: 36px;
  background: var(--trs-white);
  border: 1px solid var(--trs-gray-80);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
}

.basicInput {
  margin-top: 4px;
}

.inputGroup {
  display: table;
  width: 100%;
  margin-top: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--element-border-color);
  border-radius: 4px;
}
.inputGroup > div {
  display: table-cell;
  vertical-align: middle; /* needed for Safari */
}

.inputGroupLabel {
  background: var(--trs-gray-98);
  color: var(--trs-gray-20);
  padding: 0 8px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.inputGroupArea {
  width: 100%;
}

.inputGroup .inputStyle {
  border: 0;
  display: block;
  width: 100%;
  padding: 8px 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.helpText {
  display: block;
  font-size: 12px;
  color: var(--trs-gray-50);
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.007em;
  text-align: left;
  margin-top: 4px;
}

.errorInputState {
  border: 1px solid var(--trs-destructive-red);
  border-radius: 4px;
}

.errorTextState {
  color: var(--trs-destructive-red);
}

.errorTextState:active,
.errorTextState:focus {
  outline-color: transparent;
  outline-width: 0;
}

.disabledState {
  opacity: 0.6;
}
