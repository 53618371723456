.subheading {
  color: var(--trs-gray-40);
  font-size: 1.1rem;
}

.productHeading {
  font-size: 1.1rem;
  color: var(--trs-gray-20);
}

@media print {
  .container {
    order: 1;
  }
}
