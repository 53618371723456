.container {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  border-radius: 8px;
  background-color: #fafafa;
  padding-bottom: 1em;
  margin-bottom: 10px;
}

.container p {
  white-space: pre-line;
}
