.Tabs {
  background: #ffffff;
  border-bottom: 2px solid var(--border-color-light);
  padding: 20px 0;
  margin-bottom: 15px;
}

.Tabs ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.Tabs li.ActiveTab {
  border-bottom: 2px solid var(--brand-reece-secondary-blue);
  margin-bottom: -4px;
  color: var(--brand-reece-secondary-blue);
}

.Tabs li {
  align-items: center;
  border-bottom: 2px solid transparent;
  color: var(--text-color-medium);
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  justify-content: center;
  padding: var(--spacing-vertical) var(--spacing-horizontal);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  padding: 16px 18px;
}
