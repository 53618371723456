.appContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto 1fr;
  column-gap: 8px;
  height: 100%;
}

.applicationBody {
  grid-column: 1 / 5;
  padding: 15px 10px;
  background-color: var(--trs-gray-98);
  height: 100%;
}

.applicationHeader,
.appNavigationBar {
  grid-column: 1 / 5;
}

.contentContainer {
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .appContainer {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 16px;
  }

  .applicationHeader,
  .appNavigationBar {
    grid-column: 1 / 13;
  }

  .applicationBody {
    grid-column: 1 / 13;
    padding: 30px 20px;
  }

  .contentContainer {
    max-width: 990px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .appContainer {
    column-gap: 32px;
  }

  .contentContainer {
    max-width: 1340px;
    margin: 0 auto;
  }
}
