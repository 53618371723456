.performanceValues {
  border-radius: 8px;
  background-color: #fafafa;
  padding: 16px;
}

.performanceAttributeGrid {
  display: grid;
  grid-template: auto / 1fr 1fr;
  gap: 16px;
}

.performanceAttributeGrid > :first-child {
  text-align: end;
  line-height: 20px;
}

.performanceAttributeGrid > :last-child {
  text-align: start;
  line-height: 20px;
}

.performanceValues p {
  color: var(--text-color-grey2);
  font-size: 15px;
  margin-bottom: 16px;
}

.textPlaceholder {
  height: 20px;
  width: 100%;
  overflow: hidden;
  border-radius: 8px;
}

@media print {
  .container {
    order: 2;
  }
}
