.exceptionContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (min-width: 576px) {
  .exceptionContainer {
    flex-direction: row;
  }
}
