#content {
  height: 98%;
}

.equipmentSelectionGrid {
  display: grid;
  gap: 20px;
  padding-top: 10px;
  grid-template-columns: 1fr;
}

@media (min-width: 1100px) {
  .equipmentSelectionGrid {
    grid-template-columns: auto minmax(280px, 28%);
  }
}

.equipmentSelectionAside {
  position: sticky;
  top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: max-content;
}

@media print {
  .equipmentSelectionGrid {
    display: flex;
    flex-direction: row;
  }

  .equipmentSelectionAside {
    flex-direction: row;
  }
}
