.alertContainer {
  width: 100%;
  border-radius: 4px;
  display: flex;
  border: 1px solid rgba(224, 224, 224, 0.15);
  box-shadow: 0px 10px 23px rgba(0, 0, 0, 0.12);
}

.alertContainer:hover {
  box-shadow: 0px 24px 23px rgba(0, 0, 0, 0.12);
}

.warning {
  background-color: var(--alert-warning-background);
}

.warningSideBar {
  background-color: var(--trs-yellow-60);
}

.info {
  background-color: var(--alert-info-background);
}

.infoSideBar {
  background-color: var(--trs-blue-50);
}

.error {
  background-color: var(--alert-error-background);
}

.errorSideBar {
  background-color: var(--trs-destructive-red);
}

.success {
  background-color: var(--alert-success-background);
}

.successSideBar {
  background-color: var(--trs-green-40);
}

.alertSideBar {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 8px;
}

.alertIconContainer {
  text-align: center;
  padding-top: 10px;
  padding-left: 10px;
}

.alertText {
  padding: 12px 10px 12px 10px;
  font-size: 13px;
  line-height: 18px;
  color: var(--trs-gray-10);
}

.alertTitle {
  font-weight: bold;
  margin-bottom: 8px;
}

.alertInfoTitle {
  color: var(--trs-blue-40);
}

.alertSuccessTitle {
  color: var(--trs-green-30);
}

.alertErrorTitle {
  color: var(--trs-destructive-red);
}

.alertDescription {
  font-weight: normal;
}

.closeAlertContainer {
  padding-top: 8px;
  padding-right: 8px;
}

.closeAlertContainer button {
  background-color: transparent;
  border: 0;
}

.closeAlertContainer svg {
  fill: rgba(51, 51, 51, 0.3);
}

.closeAlertContainer svg:hover {
  border-radius: 4px;
  background-color: #d4ebf7;
  fill: var(--trs-primary-action-blue);
}

.hideAlert {
  display: none;
}

@media print {
  .alertContainer {
    display: none;
  }
}
