.dashboardCardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 16px;
}

@media (min-width: 576px) {
  .dashboardCardContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 992px) {
  .dashboardCardContainer {
    grid-template-columns: repeat(6, 1fr);
    column-gap: 32px;
  }
}
