.placeholder {
  height: 100%;
  width: 100%;
}

.shimmer {
  animation: Shimmer 3s infinite;
  background: linear-gradient(
    to right,
    var(--background-color-light),
    var(--background-color-faint),
    var(--background-color-light)
  );
  background-size: 300px 100%;
}

@keyframes Shimmer {
  from {
    background-position: -300px 0;
  }
  to {
    background-position: 300px 0;
  }
}
