.accordionContainer {
  cursor: "pointer";
  width: 100%;
  background-color: transparent;
  border: 0;
  padding: 0;
}

.accordionTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  padding: 10px 0;
  text-align: left;
}

.hideSection {
  display: none;
}
