.container {
  height: 80px;
  width: 80px;
  border-radius: 4px;
  overflow: hidden;
}

.imageContainer {
  height: 80px;
  max-height: 80px;
}

.image {
  height: 80px;
  border-radius: 4px;
}
