.sectionStyle {
  padding: 15px;
  margin-top: 18px;
  background-color: white;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

@media (min-width: 768px) {
  .sectionStyle {
    padding: 20px;
    margin-top: 24px;
  }
}

.noPadding {
  padding: 0;
}
