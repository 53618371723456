.error {
  width: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  max-height: 90vh;
  margin-top: 16px;
  border-radius: 8px;
  background-color: #fafafa;
  height: 300px;
}
