.card {
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 12px;
  color: var(--text-color-grey2);
  margin-bottom: 12px;
}

.cardTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.productDescription {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  margin-block-end: 10px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.thumbnailContainer {
  place-self: center;
}
