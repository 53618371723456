.primaryButton,
.secondaryButton,
.destructiveButton,
.secondaryRedButton {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  border: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  height: 43px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.primaryButton {
  color: white;
  background-color: var(--trs-primary-action-blue);
}

.primaryButton:hover {
  background-color: var(--trs-blue-40);
}

.primaryButton:focus {
  font-weight: 700;
  border: 4px solid white;
}

.primaryButton:active {
  font-weight: 700;
  background-color: var(--trs-primary-action-blue);
}

.primaryButton:disabled {
  background-color: rgba(0, 99, 181, 0.4);
  cursor: not-allowed;
}

.secondaryButton,
.secondaryRedButton {
  background-color: white;
  border: 1px solid var(--trs-gray-80);
}

.secondaryButton {
  color: var(--trs-gray-30);
}

.secondaryRedButton {
  color: var(--trs-destructive-red);
}

.secondaryButton:hover,
.secondaryRedButton:hover {
  background-color: var(--trs-gray-98);
  border: 0;
}

.secondaryButton:focus,
.secondaryRedButton:focus {
  background-color: var(--trs-gray-98);
  font-weight: 700;
  border: 2px solid var(--trs-gray-30);
  outline: 0;
}

.secondaryButton:active,
.secondaryRedButton:active {
  font-weight: 700;
  background-color: var(--trs-gray-98);
  border: 0;
}

.secondaryButton:disabled,
.secondaryRedButton:disabled {
  background: white;
  opacity: 0.4;
  border: 1px solid var(--trs-gray-80);
  cursor: not-allowed;
}

.destructiveButton {
  color: white;
  background-color: var(--trs-destructive-red);
}

.destructiveButton:hover {
  background-color: rgba(208, 0, 0, 0.8);
}

.destructiveButton:focus {
  font-weight: 700;
  border: 4px solid white;
}

.destructiveButton:active {
  font-weight: 700;
  background-color: var(--trs-destructive-red);
}

.destructiveButton:disabled {
  background-color: var(--trs-destructive-red);
  cursor: not-allowed;
  opacity: 0.4;
}

@media (min-width: 576px) {
  .primaryButton,
  .secondaryButton,
  .destructiveButton,
  .secondaryRedButton {
    width: unset;
    min-width: 170px;
  }
}
