.secondaryFilterContainer {
  padding: 0 0 20px;
}

.selectFieldContainer {
  width: 170px;
  margin-right: 24px;
}

.form {
  display: flex;
  flex-wrap: wrap;
}

.loadingFilter {
  height: 38px;
  margin-top: 23px;
  border-radius: 4px;
  overflow: hidden;
}

.resetButton {
  align-self: center;
  height: 2.7em;
  border: none;
  background: none;
  color: var(--trs-primary-action-blue);
  font-weight: 600;
  font-size: 14px;
}
.resetButton:hover {
  cursor: pointer;
}
.resetButton:disabled {
  opacity: var(--text-button-disabled-opacity);
  cursor: default;
}
