.bulletContainer {
  border-radius: 32px;
  height: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  padding: 0 15px 0 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: var(--trs-gray-20);
}

.warning {
  background-color: var(--alert-warning-background);
}

.error {
  background-color: var(--alert-error-background);
}

.bulletContainer span {
  padding-left: 5px;
}
