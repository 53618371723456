.radioGroupContainer {
  margin-top: 4px;
}

.radioGroup {
  line-height: 36px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.radioFlexContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  column-gap: 15px;
}

.radioLabel {
  padding-right: 26px;
}

.radioWithBorder {
  border: 1px solid var(--trs-gray-90);
  border-radius: 4px;
  margin: 10px;
  padding: 16px;
  flex-grow: 1;
}

.radioWithBorder:active,
.radioWithBorder:checked {
  border-color: var(--trs-primary-action-blue);
}

.radioStyle {
  margin-right: 12px;
}

.radioLabelDisabled {
  opacity: 0.6;
}
