.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  min-width: 320px;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

ul.listStyle {
  margin: 0;
  padding-inline-start: 15px;
}

ul.withoutBullets {
  list-style-type: none;
}

.tooltipRight {
  top: -150%;
  left: 115%;
}

.tooltipRight::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
}

.tooltipLeft {
  top: -150%;
  right: 115%;
}

.tooltipLeft::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%; /* To the right of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
}

.tooltipTop {
  bottom: 115%;
  left: 50%;
  margin-left: -160px;
}

.tooltipTop::after {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.8) transparent transparent transparent;
}

.tooltipBottom {
  top: 100%;
  left: 50%;
  margin-left: -160px;
}

.tooltipBottom::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
